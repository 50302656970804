const CURRENCY_COOKIE_KEY = 'blaze_cart_currency_code';
const BLAZE_CART_CURRENCY_CODE = 'blaze_cart_currency_code';
const KP_CART_CURRENCY_CODE = 'kp_currency';
const COOKIE_PATH = '/';
const CHECKOUT_PATH = '/checkout';
const LOGIN_PATH = '/login';
const CONTEXT_KEYS = {
  currencyKey: 'currency',
  variantKey: 'variant',
  cartIdKey: 'cartId',
  cartDataKey: 'cartData'
};

export {
  CURRENCY_COOKIE_KEY,
  BLAZE_CART_CURRENCY_CODE,
  KP_CART_CURRENCY_CODE,
  COOKIE_PATH,
  CONTEXT_KEYS,
  CHECKOUT_PATH,
  LOGIN_PATH
};
