import { BLAZE_CART_CURRENCY_CODE, CHECKOUT_PATH, LOGIN_PATH } from './constants';
import { GET_PRODUCT, GET_AUTHOR } from './queries';

const shouldShowModal = (currency, children) => {
  if (typeof window === 'undefined') return false;
  if (!children) return false;
  return !currency && !localStorage.getItem(BLAZE_CART_CURRENCY_CODE);
};

const getNewPageUrl = async ({ data, client }) => {
  const {
    data: { path }
  } = data;
  let query;
  if (path.startsWith('/product/')) {
    query = GET_PRODUCT;
  } else if (path.startsWith('/author/')) {
    query = GET_AUTHOR;
  }

  if (!query) return null;

  const [, , slug] = path.split('/');
  const { data: { foundRecords: [record] = [] } = {} } = await client.query({
    query,
    variables: { slug }
  });

  return record;
};

const getRedirectUrl = ({ previousUrl, asPath, isCheckout } = {}) => {
  if (isCheckout) return CHECKOUT_PATH;
  if (asPath && asPath !== LOGIN_PATH) return asPath;
  if (previousUrl) return previousUrl;
  if (!window) return '/';
  const {
    document: { referrer },
    location: { origin }
  } = window;
  const internalRedirect = referrer.split(origin)[1];
  return internalRedirect || '/';
};

export { shouldShowModal, getNewPageUrl, getRedirectUrl };
