/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { useApolloClient, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { EcommerceContext, useEcommerceContext } from '@blaze-cms/ecommerce-context';
import { handleLogout, decodeAccessToken } from '@blaze-cms/core-auth-ui';
import { GET_CART_QUERY } from './queries';
import useRouteContext from '../../../../../Context/useRouteContext';
import { RouteContext } from '../../../../../Context/RouteContext';
import {
  CURRENCY_COOKIE_KEY,
  BLAZE_CART_CURRENCY_CODE,
  KP_CART_CURRENCY_CODE,
  COOKIE_PATH,
  CONTEXT_KEYS,
  CHECKOUT_PATH
} from './constants';
import { BLAZE_AUTH_TOKEN_CODE, BLAZE_AUTH_EMAIL_CODE, BLAZE_CART_ID_KEY } from '../../constants';
import { shouldShowModal, getNewPageUrl, getRedirectUrl } from './helpers';

const getCurrencyValue = currency => {
  // handle legacy currency codes
  if (currency === 'uk') return 'gbp';
  if (currency === 'usa') return 'usd';

  return currency;
};

const CurrencySelectModal = ({ modifier, defaultCurrencyCode, children }) => {
  const wrapperRef = useRef(null);
  const router = useRouter();
  const client = useApolloClient();
  const { cartDataKey, cartIdKey, currencyKey } = CONTEXT_KEYS;
  const { [currencyKey]: currency, [cartIdKey]: cartId, setContextState } = useEcommerceContext(
    EcommerceContext
  );
  const { previousUrl } = useRouteContext(RouteContext);
  const [showModal, setShowModal] = useState(false);
  const [shouldFetchCart, setShouldFetchCart] = useState(false);
  const [cookies, setCookieHook, removeCookie] = useCookies();
  const { data: { cartData } = {}, loading: cartLoading } = useQuery(GET_CART_QUERY, {
    variables: { id: cartId, currency },
    fetchPolicy: 'network-only',
    skip: !cartId || !shouldFetchCart
  });

  const setCookie = (key, value, exp) => {
    const expires = exp ? new Date(exp * 1000) : undefined;
    // remove cookie if token has expired
    if (expires && expires.getTime() < Date.now()) {
      removeCookie(key);
      return false;
    }

    setCookieHook(key, value, { sameSite: 'lax', path: COOKIE_PATH, expires });
    return true;
  };

  useEffect(
    () => {
      if (!cartLoading && cartData) {
        if (shouldFetchCart) setShouldFetchCart(false);
        setContextState(cartDataKey, cartData);
      }
    },
    [cartLoading, cartData]
  );

  useEffect(
    () => {
      const currencyToUse = getCurrencyValue(currency);
      if (currencyToUse) {
        localStorage.setItem(BLAZE_CART_CURRENCY_CODE, currencyToUse);
        localStorage.setItem(KP_CART_CURRENCY_CODE, currencyToUse);
        setCookie(CURRENCY_COOKIE_KEY, currencyToUse);
        const message = {
          messageId: 'blaze-parent',
          action: 'reloadIframe',
          currency: currencyToUse
        };
        const iframe = document.querySelector('.iframe-container iframe');
        if (iframe) iframe.contentWindow.postMessage(message, '*');
        if (currencyToUse !== currency) setContextState('currency', currencyToUse);
      }

      setShowModal(shouldShowModal(currencyToUse, children));
    },
    [currency, router.asPath]
  );

  useEffect(
    () => {
      // listen to messages from iframe
      const handleIframeMessage = async event => {
        const { data } = event;
        const { action, messageId } = data;
        if (!messageId) return;
        switch (action) {
          case 'load':
            handleIframeLoad(data, event);
            break;
          case 'iframePathChange':
            handleIframePathChange(data);
            break;
          case 'updateCurrency':
            handleIframeUpdateCurrency(data);
            break;
          case 'login':
            handleIframeLogin(data);
            break;
          case 'logout':
            handleIframeLogout();
            break;
          case 'updatedCart':
            handleUpdateCart(data);
            break;
          case 'removeCart':
            handleRemoveCart(data);
            break;
          case 'sendParentDetails':
            handleQueryStringRequest(data, event);
            break;
          default:
            break;
        }
      };

      window.addEventListener('message', handleIframeMessage);
      return () => {
        window.removeEventListener('message', handleIframeMessage);
      };
    },
    [previousUrl, currency, cartId, router.asPath]
  );

  const handleQueryStringRequest = (data, event) => {
    // return currency and cartId to checkout iframe
    const response = {
      messageId: data.messageId,
      response: {
        url: window.location.href
      }
    };
    event.source.postMessage(response, '*');
  };

  const handleUpdateCart = () => {
    setShouldFetchCart(true);
  };

  const handleRemoveCart = () => {
    setContextState(cartDataKey, {});
    setContextState(cartIdKey, '');
    localStorage.removeItem(BLAZE_CART_ID_KEY);
    removeCookie(BLAZE_CART_ID_KEY);
  };

  const handleIframeLoad = (data, event) => {
    // return currency and cartId to checkout iframe
    const response = {
      messageId: data.messageId,
      response: {
        currency: currency || localStorage.getItem(BLAZE_CART_CURRENCY_CODE) || defaultCurrencyCode,
        cartId,
        authToken: cookies[BLAZE_AUTH_TOKEN_CODE],
        email: cookies[BLAZE_AUTH_EMAIL_CODE]
      }
    };
    event.source.postMessage(response, '*');

    // pass data on to check if should sync logins
    handleIframeLogin(data, true);
  };

  const handleIframePathChange = async data => {
    // route to product page if iframe path changes
    const record = await getNewPageUrl({ data, client });
    if (record) router.push('/Resolver', record.url);
  };

  const handleIframeUpdateCurrency = ({ data }) => {
    // update currency in context
    const { newCurrency } = data;
    setContextState('currency', getCurrencyValue(newCurrency));
  };

  const handleIframeLogin = ({ data }, shouldReloadArg = false) => {
    const { token, redirectUrl: oldBlazeRedirect, email } = data;
    // don't set token if same so login is synced
    if (!token || token === cookies[BLAZE_AUTH_TOKEN_CODE]) {
      return;
    }
    const { exp } = decodeAccessToken(token);
    const cookieSet = setCookie(BLAZE_AUTH_TOKEN_CODE, token, exp);
    if (!cookieSet) return;

    setCookie(BLAZE_AUTH_EMAIL_CODE, email, exp);
    const hasRedirect = !!oldBlazeRedirect && oldBlazeRedirect !== '/';

    // if is checkout reload
    const isCheckout = oldBlazeRedirect === CHECKOUT_PATH;
    const shouldReload = isCheckout || shouldReloadArg;

    // Let iframe handle internal redirect
    if (hasRedirect && !shouldReload) return;

    // if is checkout reload or navigate to checkout
    const redirectUrl = getRedirectUrl({ previousUrl, asPath: router.asPath, isCheckout });
    if (router.asPath === redirectUrl) {
      // full reload to trigger auto login after getting tok;en from iframe load
      return window.location.reload();
    }
    window.location.href = redirectUrl; // full reload to trigger auto login
  };

  const handleIframeLogout = () => {
    handleLogout(client);
    setCookie(BLAZE_AUTH_TOKEN_CODE, '');
    setCookie(BLAZE_AUTH_EMAIL_CODE, '');
    // window.location.href = '/'; // full reload to refresh everything
    router.push('/Resolver', '/');
  };

  const handleClose = () => {
    const currencyToUse = currency || defaultCurrencyCode;
    if (!currency) setContextState('currency', getCurrencyValue(currencyToUse));

    setShowModal(false);
  };

  if (!showModal || !children) return null;

  return (
    <div className="currency-select-modal__modal-overlay">
      <div className="currency-select-modal__modal-overlay__modal" ref={wrapperRef}>
        <div
          className="currency-select-modal__modal-overlay__modal__header"
          role="presentation"
          onClick={handleClose}>
          <i className="fas fa-close" />
        </div>
        <div className="currency-select-modal__modal-overlay__modal__content">{children}</div>
        <div className="currency-select-modal__modal-overlay__modal__button-container">
          <button
            className="currency-select-modal__modal-overlay__modal__button-container__button"
            type="button"
            onClick={handleClose}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

CurrencySelectModal.propTypes = {
  modifier: PropTypes.string,
  defaultCurrencyCode: PropTypes.string,
  children: PropTypes.shape()
};

CurrencySelectModal.defaultProps = {
  modifier: '',
  defaultCurrencyCode: '',
  children: null
};

export default CurrencySelectModal;
