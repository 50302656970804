import { gql } from '@apollo/client';

export const GET_PRODUCT = gql`
  query getNewPageUrl($slug: String!) {
    foundRecords: getPublishedProducts(where: { slug: $slug }) {
      id
      url
    }
  }
`;

export const GET_AUTHOR = gql`
  query getNewPageUrl($slug: String!) {
    foundRecords: getPublishedAuthors(where: { slug: $slug }) {
      id
      url
    }
  }
`;

export const GET_CART_QUERY = gql`
  query ecommerceGetCart($id: String!, $currency: String) {
    cartData: ecommerceGetCart(id: $id, currency: $currency) {
      id
      created
      updated
      formattedCreated
      formattedUpdated
      user {
        id
      }
      currency
      total
      formattedTotal
      completed
      lineItems {
        productId
        variantId
        quantity
        name
        sku
        label
        url
        price
        totalPrice
        formattedTotalPrice
        publishedProduct {
          name
          id
          url
          image {
            url
          }
        }
      }
    }
  }
`;
